import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import config from '../config';

const languages = config.dataNames[0].aiLanguages;

const AiLangs = ({ onLanguageChange }) => {
  const [selectedLangs, setSelectedLangs] = useState(() => {
    const storedLangs = localStorage.getItem('selectedLangs');
    return storedLangs ? storedLangs.split(',') : [];
  });

  useEffect(() => {
    localStorage.setItem('selectedLangs', selectedLangs.join(','));
    onLanguageChange(selectedLangs.join(','));
  }, [selectedLangs, onLanguageChange]);

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    setSelectedLangs(prev => 
      event.target.checked ? [...prev, lang] : prev.filter(l => l !== lang)
    );
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography className="section-header" gutterBottom>Select Languages</Typography>
      <Grid container spacing={1}>
        {languages.map((lang, index) => (
          <Grid item xs={6} sm={4} md={2} lg={1.2} key={lang.code}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedLangs.includes(lang.code)}
                  onChange={handleLanguageChange}
                  value={lang.code}
                />
              }
              label={lang.word}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AiLangs;