const config = {
    apiUrl: 'https://political-analysis-backend-dot-political-analysis-deploy.uw.r.appspot.com',
    defaultLimit: 10,
    defaultOffset: 0,
    routeNames: {
        subjects: 'subjects',
        topics: 'topics'
    },
    dataNames: [
        {
            subjects: 'phrases',
            subject: 'phrase',
            topics: '',
            isSubjectToCount: "yes",
            topicsSentiments: ['positive'],
            aiFields: {
                explanation: "explanation",
                translations: "translations",
                similarPhrases: "similarPhrases",
            },
            aiLanguages: [
                { code: 'zh', name: 'Chinese', word: '中文' },
                { code: 'es', name: 'Spanish', word: 'Español' },
                { code: 'hi', name: 'Hindi', word: 'हिन्दी' },
                { code: 'ar', name: 'Arabic', word: 'العربية' },
                { code: 'fr', name: 'French', word: 'Français' },
                { code: 'pt', name: 'Portuguese', word: 'Português' },
                { code: 'ru', name: 'Russian', word: 'Русский' },
                { code: 'de', name: 'German', word: 'Deutsch' },
                { code: 'ja', name: 'Japanese', word: '日本語' },
                { code: 'ko', name: 'Korean', word: '한국어' },
                { code: 'it', name: 'Italian', word: 'Italiano' },
                { code: 'vi', name: 'Vietnamese', word: 'Tiếng Việt' },
                { code: 'tr', name: 'Turkish', word: 'Türkçe' },
                { code: 'fa', name: 'Persian', word: 'فارسی' },
                { code: 'id', name: 'Indonesian', word: 'Bahasa Indonesia' },
                { code: 'bn', name: 'Bengali', word: 'বাংলা' },
                { code: 'ms', name: 'Malay', word: 'Bahasa Melayu' },
                { code: 'th', name: 'Thai', word: 'ไทย' },
                { code: 'ur', name: 'Urdu', word: 'اردو' },
                { code: 'ta', name: 'Tamil', word: 'தமிழ்' },
                { code: 'pl', name: 'Polish', word: 'Polski' },
                { code: 'uk', name: 'Ukrainian', word: 'Українська' },
                { code: 'ml', name: 'Malayalam', word: 'മലയാളം' },
                { code: 'kn', name: 'Kannada', word: 'ಕನ್ನಡ' },
                { code: 'or', name: 'Oriya', word: 'ଓଡ଼ିଆ' },
                { code: 'my', name: 'Burmese', word: 'မြန်မာစာ' },
                { code: 'mr', name: 'Marathi', word: 'मराठी' },
                { code: 'te', name: 'Telugu', word: 'తెలుగు' },
                { code: 'jv', name: 'Javanese', word: 'Basa Jawa' }
            ]    
        }
    ],
    languages: [
        { code: 'en', name: 'English' },
        // { code: 'zh', name: '中文' }
    ],
    videoWatchSeconds: 30
};
  
export default config;