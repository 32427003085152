import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fetchTopSubjects, getShareUrl } from '../services/apiService'; 
import './TopSubjects.css'; 
import config from '../config';

const TopSubjects = ({selectedAiLangs}) => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const dataName = config.dataNames[0];
  const subjectName = dataName.subject;

  useEffect(() => {
    const loadTopSubjects = async () => {
      setLoading(true);
      try {
        const data = await fetchTopSubjects({page, aiLangs: selectedAiLangs});
        setSubjects(data);
      } catch (err) {
        setError('Failed to load top subjects');
      } finally {
        setLoading(false);
      }
    };

    loadTopSubjects();
  }, [page, selectedAiLangs]);

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const joinedLangs = aiField => aiField && Object.entries(aiField).map(([language, phrases], index) => (
    <>
      <span key={`${language}-${index}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`}><i>{phrases[0]}</i></span><br />
    </>
  ));

  return (
    <div className="top-subjects-container">
      <Typography className="section-header" gutterBottom>
        {t('topSubjects')}
      </Typography>
      <Grid container spacing={2}>
        {subjects.map((subject, index) => (
          <Grid item xs={12} sm={6} md={3} key={`subject-${subject.id}-${index}`}>
            <Card className="subject-card">
              <CardContent>
                <Typography variant="h8" component="div">
                    <a href={getShareUrl(subject[subjectName], lang)} target="_blank" className="subject-link">
                        {index + 1 + (page - 1) * 12}. {subject[subjectName][0]} ({subject['count']})
                    </a>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {joinedLangs(subject[`${dataName.aiFields.similarPhrases}`])}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div className="pagination-buttons">
        <Button variant="contained" color="inherit" onClick={handlePrevious} disabled={page === 1}>
          Previous 12
        </Button>
        <Button variant="contained" color="inherit" onClick={handleNext} style={{ marginLeft: '10px' }}>
          Next 12
        </Button>
      </div>
    </div>
  );
};

export default TopSubjects;