// AdModal.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Box, Button } from '@mui/material';
import GoogleAd from './GoogleAd';

const AdModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="ad-modal-title"
      aria-describedby="ad-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <h5 id="ad-modal-title">{t('advertisement')}</h5>
        <div id="ad-modal-description">
          <GoogleAd />
          <hr />
        </div>
        <Button onClick={handleClose}>{t('close')}</Button>
      </Box>
    </Modal>
  );
};

export default AdModal;